import { ReactNode } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

// Headerメニューのテキスト
export function HeaderMenuText({ text, path }: { text: string, path: string }) {
  const { pathname } = useLocation()
  const textColorStyle = pathname === path ? "text-gray-700" : "text-gray-400"
  return (<>
    {path === '/contact' ? 
      <Link 
        to={"https://docs.google.com/forms/d/e/1FAIpQLSem3Gl7l_-xhRY_wqAW1_OhoHLroRognKYL2VennlCilk3oGw/viewform?usp=pp_url"}
        className={`text-base lg:text-lg hover:cursor-pointer
        font-semibold hover:text-gray-200
        transition duration-100 items-center inline-flex ${textColorStyle}`} 
      >
        {text}
      </Link> 
    :
      <NavLink
        to={path}
        className={`text-base lg:text-lg hover:cursor-pointer
        font-semibold hover:text-gray-200
        transition duration-100 items-center inline-flex ${textColorStyle}`}
      >
        {text}
      </NavLink>
    }
  </>)
}

// normal p テキスト
export function PText({ children }: { children: ReactNode }) {
  return (
    <p className="text-sm lg:text-base">{children}</p>
  )
}