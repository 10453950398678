import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import Index from '../pages/web/top/Index';
import About from '../pages/web/about/Index';
import Header from '../pages/web/parts/Header';
import Footer from '../pages/web/parts/Footer';
import Next from '../pages/web/next/Index';
import News from '../pages/web/news/Index';
import OtherCoreogurefer from '../pages/web/othercoreogurefer/Index';
import Archive from '../pages/web/archive/Index';

// ルーティング
export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
        <FrontRouter />
        <AdminRouter />
    </BrowserRouter>
  );
}

// フロントルーティング
function FrontRouter() {
  return (<>
  <Routes>
    <Route path="/" element={<FrontBase />}>
      <Route index element={<Index />} />
      <Route path="/about" element={<About />} />
      <Route path="/othercoreogurefer" element={<OtherCoreogurefer />} />
      <Route path="/next" element={<Next />} />
      <Route path="/archive" element={<Archive />} />
      <Route path="/archive/:id" element={<Archive />} />
      <Route path="/news/:id" element={<News />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
  </>);
}
function FrontBase() {
  return (
    <>
      <Header/>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

// 管理者ルーティング
function AdminRouter() {
  return (
      <Routes>
        <Route path="admin">
          <Route index element={<>b</>} />
        </Route>
      </Routes>
  );
}

/**
 * 遷移時、トップへスクロールコンポーネント 
 */
const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return <></>
}


// 404
function NotFound() {
  return (<>
    <header className='flex justify-center'>
      <a href="/" className="text-black-800 inline-flex items-center gap-2.5 text-2xl font-bold md:text-3xl" aria-label="logo">
        <img src={`${process.env.REACT_APP_URL}/images/odoriba_logo.png`} alt="logo" width={75}/> 
        <img src={`${process.env.REACT_APP_URL}/images/odoriba_name.png`} alt="logo" width={150}/>
      </a>
    </header>
    <main className='text-center h-full'>
      <div className='mt-20 text-2xl text-gray-400'>404 | NotFound</div>
      <div className='mt-5 text-base text-gray-400'>お探しのページが見つかりませんでした。</div>
    </main>
    <footer className="absolute bottom-0 right-0 w-full block my-5 mx-2">
      <div className="flex justify-end">
        <div className="text-xs">
        Copyright © 2023 オドリバ | official web  All Rights Reserved.
        </div>
      </div>
    </footer>
  </>)
}

