import { useNavigate } from "react-router-dom";
import { isEven } from "../../../../../lib/function";

export default function NewsList() {
  return (
    <div className="w-full border border-gray-300 rounded-lg max-h-48 sm:max-h-96 overflow-y-scroll">
      {List.map((item, index) => (
        <News text={item.text} date={item.date} path={item.path} index={index} key={index} />
      ))}
    </div>
  )
}

const List = [
  { text: "『ライスボールダンスクラブ ことば mono くうき』のダンス評を公開しました。", date: "2023/09/28", path: "/news/5" },
  { text: "ライスボールダンスクラブへの招待vol.01 オドリバって？　ライスボールダンスクラブって？　を公開しました", date: "2023/06/10", path: "/news/4" },
  { text: "ライスボールダンスクラブ ことば mono くうき 児玉健吾WIP 予約開始しました", date: "2023/06/10", path: "/news/3" }, 
  { text: "ライスボールダンスクラブ ことば mono くうき チケット販売を開始しました", date: "2023/06/10", path: "/news/2" },
  { text: "HPを開設しました", date: "2023/06/10", path: "/news/1" },
]

interface INewsProps {
  date: string,
  text: string,
  path: string,
  index: number
}

function News({date, text, path, index}: INewsProps) {
  const navigate = useNavigate()
  const bgStyle = isEven(index) ? "bg-gray-100" : "bg-white"
  return (
    <div 
      onClick={() => navigate(path)}
      className={`p-4  hover:cursor-pointer ${bgStyle}`}
    >
      <div className="flex flex-col sm:flex-row text-sm lg:text-base">
        <div className="mr-2">{date}</div>
        <div>{text}</div>
      </div>
    </div>
  )
}