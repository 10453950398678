import { Navigate, useParams } from "react-router-dom"
import { MotionScreen, MotionTitle } from "../parts/Motion"
import { DetailGrayBox, ImageCenterBox } from "../parts/BoxBase"
import { PText } from "../../../layouts/text/Base"
import { ReserveButton } from "../next/Index"


export default function News() {
  const { id } = useParams()
  return (
    <div className="w-full">
      <MotionScreen>
        <MotionTitle>NEWS</MotionTitle>
        <div>
          {typeof id === 'string' ? 
          <NewsDetail id={id} /> : 
          <Navigate to='/notfound' />}
        </div>
      </MotionScreen>
    </div>
  )
}

interface NewsDetailProps {
  id: string
}

function NewsDetail({id}: NewsDetailProps) {
  return (<>
    {id === '1' ?
      <div>
        <div className='text-2xl font-bold mb-4'>
          HPを開設しました
        </div>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/news/news1.png`} alt="HPを開設しました" width={300} />
        <DetailGrayBox>
          <PText>こまばアゴラ劇場における公演を前にオドリバの新しいホームページを公開しました。</PText>
          <PText>初お目見えの団体ロゴや公演アーカイブなど、いろいろご覧になってみてください。</PText>
          <br />
          <PText>今後とも、オドリバをよろしくお願いいたします。</PText>
        </DetailGrayBox>
      </div>
    : id === '2' ?
      <div>
        <div className='text-2xl font-bold mb-4'>
          ライスボールダンスクラブ ことば mono くうき チケット販売を開始しました
        </div>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/news/news2.jpg`} alt="ライスボールダンスクラブ ことば mono くうき チケット販売を開始しました" width={500} />
        <DetailGrayBox>
          <PText>こまばアゴラ劇場にて2023年7月14日から16日まで実施予定です。</PText>
          <PText>詳しくはこちらをご覧ください。</PText>
          <div className="mt-5">
            <ReserveButton>▲▽  ご予約はこちらから ▽▲</ReserveButton>
          </div>
        </DetailGrayBox>
      </div>
    : id === '3' ?
      <div>
        <div className='text-2xl font-bold mb-4'>
          ライスボールダンスクラブ ことば mono くうき 児玉健吾WIP 予約開始しました
        </div>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/news/news3.png`} alt="ライスボールダンスクラブ ことば プログレス 予約開始しました" width={700} />
        <DetailGrayBox>
          <PText>ライスボールダンスクラブ ことば mono くうきではAチームのワークインプログレスを実施しております。プログレス三度目は児玉健吾による作品『だから、きさにごういた』を一足先に上演いたします。</PText>
          <PText>上演時間は20分程度になります。</PText>
          <PText>土日のマチソワ間などに、ぜひ。</PText>
          <div className="mt-5">
            <ReserveButton>▲▽  ご予約はこちらから ▽▲</ReserveButton>
          </div>
        </DetailGrayBox>
      </div>
    : id === '4' ?
      <div>
        <div className='text-2xl font-bold mb-4'>
          ライスボールダンスクラブへの招待vol.01 オドリバって？　risesボールダンスクラブって？　を公開しました
        </div>
        <DetailGrayBox>
          <PText>ライスボールダンスクラブ ことば mono くうき。</PText>
          <PText>ちょっとわかりにくいこの企画についてをご説明するnoteを公開いたしました。</PText>
          <PText>ご一読いただければ幸いです。</PText>
        </DetailGrayBox>
      </div>
    : id === '5' ?
      <div>
        <div className="text-2xl font-bold mb-4">
          『ライスボールダンスクラブ ことば mono くうき』のダンス評を公開しました。
        </div>
        <DetailGrayBox>
          <PText>『ライスボールダンスクラブ ことば mono くうき』のダンス評を公開しました。</PText>
          <PText>ことば編、もの編、くうき編と3名から2作品ずつ執筆していただきました。</PText>
          <PText>お読みいただければ幸いです。</PText>
          <br />
          <PText>ことば編（綾門優季）</PText>
          <PText><a className="underline hover:underline-offset-0" href="https://note.com/clean_clover569/n/ne2443911fd5d">https://note.com/clean_clover569/n/ne2443911fd5d</a></PText>
          <br />
          <PText>もの編（丘田ミイ子）</PText>
          <PText><a className="underline hover:underline-offset-0" href="https://note.com/clean_clover569/n/n40165edff6da">https://note.com/clean_clover569/n/n40165edff6da</a></PText>
          <br />
          <PText>くうき編（大川智史）</PText>
          <PText><a className="underline hover:underline-offset-0" href="https://note.com/clean_clover569/n/nf68c6432f4c7">https://note.com/clean_clover569/n/nf68c6432f4c7</a></PText>
        </DetailGrayBox>
      </div>
    :
      <Navigate to='/notfound' /> 
    }
  </>)
}