import { PText } from "../../../layouts/text/Base";
import { DetailGrayBox, ImageCenterBox } from "../parts/BoxBase";
import { MotionLgText, MotionScreen, MotionTitle } from "../parts/Motion";

export default function About() {
  return (
    <>
      <div className="w-full">
        <MotionScreen>
          <MotionTitle>About</MotionTitle>
          <ImageCenterBox
            width={300}
            src={`${process.env.REACT_APP_URL}/images/logo.png`}
            alt="オドリバ"
          />
          <DetailGrayBox>
            <PText>
              おにぎり海人の呼びかけで結成した上演芸術のスタッフが集まったコンテンポラリーダンス企画ユニット。「ここに集まれば踊ることができる」というダンサーへの機会の提供と、「創作の中でダンサーが自分の活動のその先を考えることができる」ダンサーのキャリアにとっての踊り場となることを目的に2021年より活動中。
            </PText>
            <br/>
            <PText>主な選出歴にマグカルシアター2022、アーツカウンシルスタートアップ助成など。</PText>
          </DetailGrayBox>
          <hr className="my-10" />
          <MotionTitle>Member</MotionTitle>
          <MotionLgText>おにぎり海人(onigiri kaito)</MotionLgText>
          <DetailGrayBox>
            <PText>1996年生まれ。オドリバ主宰・音響。千葉県立白井高等学校卒業。高卒。舞台音響。DJ。</PText>
            <PText>2020年度よりおにぎり海人の名で活動を開始。おにぎりはあまり好きではない。</PText>
            <PText>かまどキッチンに2018年より舞台音響として参加。2019年の団体化に伴い劇団員に。劇団では外部スタッフのブッキングなど制作面の補佐も行う。</PText>
            <br/>
            <PText>参加したダンスカンパニーに森下スタンド、DANCE PJ REVO、富山のはるか、鮭スペアレなど。</PText>
          </DetailGrayBox>
          <br/>
          <MotionLgText>佃直哉(tsukuda naoya)</MotionLgText>
          <DetailGrayBox>
            <PText>1995年生まれ。オドリバでは企画制作を担当。劇団かまどキッチン主宰。桜美林大学芸術文化学群演劇専修卒業。</PText>
            <PText>大学では演劇ユニット「サンプル」を主宰する松井周に師事。2020年よりゲーム・アニメ等の分野にて脚本家として活動。</PText>
          </DetailGrayBox>
        </MotionScreen>
      </div>
    </>
  )
}