import Router from './components/router/Router';

function App() {
  return (
    <div className="bg-white pb-6 sm:pb-8 lg:pb-12">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <Router />
      </div>
    </div>
  );
}

export default App;
