import React from 'react';
import { MotionScreen, MotionTitle } from '../parts/Motion';
import { DetailGrayBox } from '../parts/BoxBase';
import { PText } from '../../../layouts/text/Base';

const Next: React.FC = () => {

  return (
    <MotionScreen>
      <MotionTitle>Next</MotionTitle>
      <div>
        <div>
          <div className='text-2xl font-bold mb-4'>『 ライスボールダンスクラブ ことば mono くうき 』</div>
          <img src={`${process.env.REACT_APP_URL}/images/fryer.jpg`} alt="next" className="mx-auto" />
          <DetailGrayBox>
            <PText>
              こまばアゴラ劇場にて企画しているオドリバのショーケース公演。身体の存在を前提に、舞台空間上に存在する普遍的な要素と向き合いダンスを創作するアーティストを招いて公演を行う。何によって身体は動き/ 動かされ、何によって身体は止まり/止められるのか。
            </PText>
            <br/>
            <PText>ことば/もの/くうきのレイヤーから考察する。</PText>
          </DetailGrayBox>
        </div>
        <div>
          <div>
            <SubTitle>公演情報</SubTitle>
            <div className='text-base lg:text-lg font-bold'>What’s ライスボールダンスクラブ？</div>
            <DetailGrayBox>
              <PText>ライスボールダンスクラブはオドリバが複数の振付家を招いて行うダンスショーケース、もしくはダンスクラブ。オドリバが用意した空間とスタッフワークでもって、ダンサーのクリエイティブを最大限に発揮させます。</PText>
            </DetailGrayBox>
            <br/>
            <div className='text-base lg:text-lg font-bold'>What’s ことば mono くうき？</div>
            <DetailGrayBox>
              <PText>今回のダンスクラブは、こまばアゴラ劇場という現代演劇を主に上演する劇場です。「ことば」→「くうき」→「もの」三つの視点を通じて、劇作家、振付け家、舞踏にバンド、現代サーカス等様々な人を巻き込み、演劇の劇場をコンテンポラリーなダンスフロアへとフェードさせます。お楽しみに。</PText>
            </DetailGrayBox>
            <div className='text-sm lg:text-base my-5'>
              <div>とき: 2023年7月13日(木)-16日(日)</div>
              <div>場所: <a href="http://www.komaba-agora.com">こまばアゴラ劇場</a>（東京都目黒区駒場1丁目11−13）</div>
            </div>
            <img src={`${process.env.REACT_APP_URL}/images/schedule.png`} alt="告知画像" className="mx-auto p-4" />
            <div className='text-sm lg:text-base'>◆チケット</div>
            <ul className='ml-3 text-sm lg:text-base'>
              <li>一般 3,500 円</li>
              <li>U 25 3,000 円</li>
              <li>高校生 1,500 円</li>
              <li>チケット半券提示で500 円割引</li>
            </ul>
            <div className='text-red-400 text-sm ml-3'>＊U25、高校生チケットをご予約のお客様は当日受付にて学生証等のご提示をお願いします。</div>
            <div className='text-center my-5'>
              <ReserveButton>▲▽  ご予約はこちらから ▽▲</ReserveButton>
            </div>
          </div>
          <div className='my-5'>
            <SubTitle>クレジット</SubTitle>
            <div>
              <div className='my-2 text-sm lg:text-base'>
                <div>◆出演</div>
                <div className='flex flex-col lg:flex-row ml-3'>
                  <div className='w-full lg:w-1/2'>
                    <div>Aチーム</div>
                    <ul className='ml-2'>
                      <li>ことば: 児玉健吾『だから、きさにごういた』</li>
                      <li>作・演出: 児玉健吾(かまどキッチン)</li>
                      <li>出演: 浦田かもめ、真壁遥</li>
                    </ul>
                    <br/>
                    <ul className='ml-2'>
                      <li>くうき: DANCE PJ REVO『POPCORN FLOOR』</li>
                      <li>振付・構成・演出: 田村興一郎</li>
                      <li>出演: 石川佳音、岩城かのこ、神夢想、七里海流クノー、高橋春香、田村興一郎</li>
                    </ul>
                    <br/>
                    <ul className='ml-2'>
                      <li>もの: オカダヒロエ『up to YOU』</li>
                      <li>振付・構成: オカダヒロエ</li>
                      <li>出演: オカダヒロエ、堀川 男</li>
                    </ul>
                  </div>
                  <div className='w-full lg:w-1/2 lg:mt-0 mt-3'>
                    <div>Bチーム</div>
                    <ul className='ml-2'>
                      <li>ことば: エリア 51『煮込みすぎて.zip』</li>
                      <li>ことば: 神保治暉</li>
                      <li>音: 鈴木美結、神保治暉、廣戸彰彦、中野志保</li>
                      <li>体: 中嶋千歩</li>
                    </ul>
                    <br/>
                    <ul className='ml-2'>
                      <li>くうき: 神田初音ファレル『懺肉祭』</li>
                      <li>振付・構成・演出・出演: 神田初音ファレル(オフワンズ)</li>
                    </ul>
                    <br/>
                    <ul className='ml-2'>
                      <li>もの: さかさ舟『遠くに』</li>
                      <li>演出・出演: 鈴木仁、松永将汰</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='my-2 text-sm lg:text-base'>
                <div>◆スタッフ</div>
                <ul className='ml-3'>
                  <li>音響: おにぎり海人(オドリバ/かまどキッチン)</li>
                  <li>照明: 上田茉衣子(LICHT-ER)</li>
                  <li>舞台監督: 久保田智也</li>
                  <li>衣装アドバイザー: 岡花暖</li>
                  <li>宣伝美術: 江原未来</li>
                  <li>映像撮影: 小林勇太(T.C.O.C.)</li>
                  <li>スチール: マコトオカザキ</li>
                  <li>企画制作: オドリバ</li>
                  <li>制作補佐: 渡邉結衣(みちばたカナブン / にもじ)</li>
                  <li>当日運営: 宮野風紗音(かるがも団地)</li>
                  <li>プロデュース: 佃直哉(オドリバ/かまどキッチン)</li>
                  <li>公演アドバイザー: 中川絢音(水中めがね∞)</li>
                </ul>
              </div>
              <div className='my-2 text-sm lg:text-base'>
                <div>◆協力</div>
                <div className='ml-3'>エリア51、オフワンズ、かまどキッチン、さかさ舟、DANCE PJ REVO、水中めがね∞、LICHT-ER、みちばたカナブン、かるがも団地、T.C.O.C、にもじ、こまばアゴラ劇場</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </MotionScreen>
  );
};

type Props = {
  children: React.ReactNode;
};

export const SubTitle: React.FC<Props> = ({children}) => {
  return (
    <div className='py-3 text-center text-xl font-semibold border-y-2 my-5 text-gray-600'>{children}</div>
  )
}

// aタグでボタン風レイアウトコンポーネント
export const ReserveButton: React.FC<Props> = ({children}) => {
  return (
    <a className='inline-block px-5 py-2 text-white bg-black rounded-full' href='https://www.quartet-online.net/ticket/odoriba202307'>{children}</a>
  )
}
    

export default Next;

