import { Link, NavLink, useLocation } from "react-router-dom";
import { LogoTitle } from "../../../layouts/Logo";
import MenuButton from "../top/parts/parts/Menu";
import { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Dialog, Transition } from "@headlessui/react";
import { HeaderMenuText } from "../../../layouts/text/Base";

export default function Header() {
  return (
    <header className="flex items-center justify-between py-4 md:py-8">
      <LogoTitle />
      <nav className="hidden gap-10 lg:flex">
        <HeaderMenuText text="About" path="/about" />
        <HeaderMenuText text="OtherCoreogurefer" path="/othercoreogurefer" />
        <HeaderMenuText text="Next" path="/next" />
        <HeaderMenuText text="Archive" path="/archive" />
        <HeaderMenuText text="Contact" path="/contact" />
        <div className="flex">
          <Link
            to="https://twitter.com/odoriba_rice?s=21&t=twScVjKZjmjtkL8gaRgabQ" 
            className="flex items-center justify-center"
          >
            <img src={`${process.env.REACT_APP_URL}/images/twitter.png`} alt="twitter" className="mr-3 h-5" />
          </Link>
          <Link
            to="https://note.com/clean_clover569" 
            className="flex items-center justify-center"
          >
            <img src={`${process.env.REACT_APP_URL}/images/note.png`} alt="note" className="mr-3 h-12" />
          </Link>
        </div>
      </nav>
      <MobileMenu />
      {/**
      <MenuButton /> 
       */}
    </header>
  )
}

function MobileMenu() {
  const [open ,setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)
  return (<>
    <div className="relative inline-block text-left lg:hidden">
      <div
        onClick={onOpen}
      >
        <FontAwesomeIcon icon={faBars}
          className="h-5 w-5 text-gray-400 hover:text-gary-200"
          aria-hidden="true"
        />
      </div>
    </div>
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen h-screen w-screentransform overflow-hidden  bg-white p-4 md:p-8 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <div className="text-black-800 inline-flex items-center gap-2.5 text-2xl font-bold md:text-3xl" aria-label="logo">
                      <img src={`${process.env.REACT_APP_URL}/images/odoriba_logo.png`} alt="logo" width={75}/> 
                      <img src={`${process.env.REACT_APP_URL}/images/odoriba_name.png`} alt="logo" width={150}/>
                    </div>
                    <div 
                      className="my-auto"
                      onClick={onClose}
                    >
                      <FontAwesomeIcon icon={faXmark}
                        className="h-5 w-5 text-gray-400 hover:text-gary-200"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="absolute inset-x-1">
                    <div className="flex flex-col mt-10">
                        <MobileHeaderMenuText text="Top" path="/" onClose={onClose}/>
                        <MobileHeaderMenuText text="About" path="/about" onClose={onClose}/>
                        <MobileHeaderMenuText text="OtherCoreogurefer" path="/othercoreogurefer" onClose={onClose}/>
                        <MobileHeaderMenuText text="Next" path="/next" onClose={onClose}  />
                        <MobileHeaderMenuText text="Archive" path="/archive" onClose={onClose}/>
                        <MobileHeaderMenuText text="Contact" path="/contact" onClose={onClose}/>
                    </div>
                    <div className="flex justify-center mt-10">
                      <Link
                        to="https://twitter.com/odoriba_rice?s=21&t=twScVjKZjmjtkL8gaRgabQ" 
                        className="flex items-center justify-center"
                        tabIndex={-1}
                      >
                        <img src={`${process.env.REACT_APP_URL}/images/twitter.png`} alt="twitter" className="mr-3 h-5" />
                      </Link>
                      <Link
                        to="https://note.com/clean_clover569" 
                        className="flex items-center justify-center"
                        tabIndex={-1}
                      >
                        <img src={`${process.env.REACT_APP_URL}/images/note.png`} alt="note" className="mr-3 h-14" />
                      </Link>
                    </div> 
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
      </Dialog>
    </Transition>
  </>)
}

// Headerメニューのテキスト
function MobileHeaderMenuText({ text, path, onClose }: { text: string, path: string, onClose: () => void }) {
  const { pathname } = useLocation()
  const textColorStyle = pathname === path ? "text-gray-700" : "text-gray-400"
  return (<div className="mb-4 text-center">
    {path === '/contact' ? 
      <Link
        onClick={onClose}
        to={"https://docs.google.com/forms/d/e/1FAIpQLSem3Gl7l_-xhRY_wqAW1_OhoHLroRognKYL2VennlCilk3oGw/viewform?usp=pp_url"}
        className={`text-2xl lg:text-lg font-semibold mx-4 my-1 transition duration-100 items-center inline-flex  ${textColorStyle}`}
        tabIndex={-1} 
      >
        {text}
      </Link> 
    :
      <NavLink
        onClick={onClose}
        to={path}
        className={`text-2xl lg:text-lg font-semibold mx-4 my-1 transition duration-100 items-center inline-flex  ${textColorStyle}`}
        tabIndex={-1}
      >
        {text}
      </NavLink>
    }
  </div>)
}