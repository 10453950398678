import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

const baseFadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const baseSlideUp = {
  hidden: { y: 20, opacity: 0 },
  visible: { y: 0, opacity: 1 },
}

type MotionProps = {
  children: ReactNode
}

export const MotionScreen: React.FC<MotionProps> = ({children}) => {
  return (
    <motion.div className="flex flex-col min-h-screen" initial="hidden" animate="visible" variants={baseFadeIn}>
      {children}
    </motion.div>
  )
}

export const MotionTitle: React.FC<MotionProps> = ({children}) => {
  return (
    <motion.h2 className="text-2xl lg:text-3xl font-bold text-gray-600 mb-6" variants={baseSlideUp}>
      {children}
    </motion.h2>
  )
}

export const MotionLgText: React.FC<MotionProps> = ({children, ...props}) => {
  return (
    <motion.p className={`text-gray-700 text-lg ${props}`} variants={baseSlideUp}>
      {children}
    </motion.p>
  )
}

export const MotionMdText: React.FC<MotionProps> = ({children, ...props}) => {
  return (
    <motion.p className={`text-gray-700 text-md ${props}`} variants={baseSlideUp}>
      {children}
    </motion.p>
  )
}

export const MotionSmText: React.FC<MotionProps> = ({children, ...props}) => {
  return (
    <motion.p className={`text-gray-700 text-sm ${props}`} variants={baseSlideUp}>
      {children}
    </motion.p>
  )
}

export const MotionDiv: React.FC<MotionProps> = ({children, ...props}) => {
  return (
    <motion.div className={`${props} text-gray-700 ml-3`} variants={baseSlideUp}>
      {children}
    </motion.div>
  )
}