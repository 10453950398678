import { MotionScreen, MotionTitle } from "../parts/Motion";

export default function OtherCoreogurefer() {
  return (
    <>
      <div className="w-full">
        <MotionScreen>
          <MotionTitle>OtherCoreogurefer</MotionTitle>
          <div className="text-center">
            <div className='mt-20 text-2xl lg:text-3xl font-bold text-gray-400 mb-6'>COMING SOON...</div>
          </div>
        </MotionScreen>
      </div>
    </>
  )
}