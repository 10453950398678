import { ReactNode } from "react"

type BoxBaseProps = {
  children: ReactNode
}

export const DetailGrayBox: React.FC<BoxBaseProps> = ({children}) => {
  return (
    <div className="bg-gray-100 p-5 my-5 text-sm rounded-lg">
      {children}
    </div>
  )
}

type ImageBoxProps = {
  width: number
  src: string
  alt: string
}

export const ImageCenterBox: React.FC<ImageBoxProps> = ({width, src, alt}) => {
  return (
    <div className="items-center">
      <img className="mx-auto" src={src} alt={alt} width={width}/>
    </div>
  )
}