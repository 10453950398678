import { Link } from "react-router-dom";
import { HeaderMenuText } from "../../../layouts/text/Base";
import { ImageCenterBox } from "./BoxBase";

export default function Footer() {
  return (
    <footer className="absolute right-0 w-full block my-5 border-gray-400 border-t">
      <div className="mx-6 pt-10 pb-5 lg:pb-10 text-center md:text-left">
        <div className="grid-1 grid lg:gap-8 lg:grid-cols-3">
          <div className="">
            <div className="mb-0 lg:mb-4 flex items-center justify-center font-semibold uppercase">
              <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/logo.png`} alt="logo" width={200} />
            </div>
          </div>
          <div className="flex flex-col text-center">
            <h5
              className="mb-4 flex text-base lg:text-lg font-semibold uppercase justify-center">
              Menu
            </h5>
            <div className="mb-2">
              <HeaderMenuText text="About" path="/about" />
            </div>
            <div className="mb-2">
              <HeaderMenuText text="OtherCoreogurefer" path="/othercoreogurefer" />
            </div>
            <div className="mb-2">
              <HeaderMenuText text="Next" path="/next" />
            </div>
            <div className="mb-2">
              <HeaderMenuText text="Archive" path="/archive" />
            </div>
            <div className="mb-2">
              <HeaderMenuText text="Contact" path="/contact" />
            </div>
          </div>
          
          <div>
            <div className="flex flex-col justify-between h-full">
              <div className="flex justify-center lg:justify-start">
                <Link
                  to="https://twitter.com/odoriba_rice?s=21&t=twScVjKZjmjtkL8gaRgabQ" 
                  className="mb-4 flex items-center justify-center mr-1"
                >
                  <img src={`${process.env.REACT_APP_URL}/images/twitter.png`} alt="twitter" className="mr-3 h-5" />
                </Link>
                <Link
                  to="https://note.com/clean_clover569" 
                  className="mb-4 flex items-center justify-center"
                >
                  <img src={`${process.env.REACT_APP_URL}/images/note.png`} alt="note" className="mr-3 h-12" />
                </Link>
              </div>
            <div
              className="flex justify-center text-sm lg:font-semibold uppercase">
              Copyright © 2023 オドリバ | official web  All Rights Reserved.
            </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}