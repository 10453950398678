import { Link, useParams } from "react-router-dom";
import { ImageCenterBox } from "../parts/BoxBase";
import { MotionScreen, MotionTitle } from "../parts/Motion";
import ArchiveDetail from "./Detail";

export default function Archive() {
  const { id } = useParams()
  return (
    <MotionScreen>
      <MotionTitle>Archive</MotionTitle>
      {typeof id === 'string' ?
        <ArchiveDetail id={id} /> 
      :
        <div>
          <section>
            <Link to="/archive/5">
              <div className="text-sm lg:text-base font-bold mb-5">focus01.浅川奏瑛｜人間っていいな。</div>
              <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive5-1.jpg`} alt="archive5-1" width={500} />
            </Link>
          </section>
          <hr className="my-10" />
          <section>
            <Link to="/archive/4">
              <div className="text-sm lg:text-base font-bold mb-5">ライスボールダンスクラブ</div>
              <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive4-1.jpg`} alt="archive4-1" width={600} />
            </Link>
          </section>
          <hr className="my-10" />
          <section>
            <Link to="/archive/3">
              <div className="text-sm lg:text-base font-bold mb-5">テンポンコリラーンダスの夜（YPAM）</div>
              <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive3-1.jpg`} alt="archive3-1" width={600} />
            </Link>
          </section>
          <hr className="my-10" />
          <section>
            <Link to="/archive/2">
              <div className="text-sm lg:text-base font-bold mb-5">ライスボールダンスクラブα</div>
              <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive2-1.jpg`} alt="archive2-1" width={600} />
            </Link>
          </section>
          <hr className="my-10" />
          <section>
            <Link to="/archive/1">
              <div className="text-sm lg:text-base font-bold mb-5">ライスボールダンスクラブA</div>
              <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive1-1.jpg`} alt="archive1-1" width={600} />
            </Link>
          </section>
        </div>
      }
    </MotionScreen>
  )
}