import { Navigate } from "react-router-dom"
import { SubTitle } from "../next/Index"
import { DetailGrayBox, ImageCenterBox } from "../parts/BoxBase"
import { PText } from "../../../layouts/text/Base"


interface ArchiveDetailProps {
  id: string
}

export default function ArchiveDetail({id}: ArchiveDetailProps) {
  return (<>
    {id === '1' ? <>
      <div className="text-2xl font-bold mb-4">
        ライスボールダンスクラブA
      </div>
      <div className='flex flex-col lg:flex-row gap-3'>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive1-1.jpg`} alt="archive1-1" width={1000} />
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive1-2.jpg`} alt="archive1-2" width={1000} />
      </div>
      <div>
        <SubTitle>公演情報</SubTitle>
        <div className="text-sm lg:text-base">
          <div>とき: 2021年7月30日</div>
          <div>場所: ホームギャラリーステッチ</div>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆参加振付家</div>
          <ul className="ml-3">
            <li>石原朋香</li>
            <li>数澪里</li>
            <li>山口なぎさ</li>
          </ul>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆スタッフ</div>
          <ul className="ml-3">
            <li>主宰: おにぎり海⼈</li>
            <li>制作: 松波春奈</li>
            <li>企画: 佃直哉</li>
            <li>　　 河野⾥咲⼦</li>
            <li>　　 まつなみはる奈</li>
            <li>⾳響・照明: おにぎり海⼈</li>
            <li>撮影: 児⽟健吾</li>
            <li>協⼒: かまどキッチン このめ</li>
          </ul>
        </div>
      </div>
    </> : id === '2' ? <>
      <div className="text-2xl font-bold mb-4">
        ライスボールダンスクラブα
      </div>
      <div className='flex flex-col lg:flex-row gap-3'>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive2-1.jpg`} alt="archive2-1" width={1000} />
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive2-2.jpg`} alt="archive2-2" width={1000} />
      </div>
      <div>
        <SubTitle>公演情報</SubTitle>
        <div className="text-sm lg:text-base">
          <div>とき: 2021年9月3日</div>
          <div>場所: ホームギャラリーステッチ</div>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆参加振付家</div>
          <ul className="ml-3">
            <li>オカダヒロエ</li>
            <li>数澪里</li>
          </ul>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆スタッフ</div>
          <ul className="ml-3">
            <li>主宰: おにぎり海⼈</li>
            <li>制作: 田邊樹</li>
            <li>企画: 佃直哉</li>
            <li>　　 河野⾥咲⼦</li>
            <li>　　 まつなみはる奈</li>
            <li>　　 数澪里</li>
            <li>舞台監督: 扇谷紗帆</li>
            <li>⾳響: おにぎり海⼈</li>
            <li>関連企画: 石原朋香</li>
            <li>協⼒: AOL かまどキッチン このめ 大嵜逸生 齋藤未空 吉野葵</li>
          </ul>
        </div>
      </div>
    </> : id === '3' ? <>
      <div className="text-2xl font-bold mb-4">
        テンポンコリラーンダスの夜（YPAM）
      </div>
      <div className='flex flex-col lg:flex-row gap-3'>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive3-1.jpg`} alt="archive3-1" width={2000} />
      </div>
      <div>
        <SubTitle>公演情報</SubTitle>
        <div className="text-sm lg:text-base">
          <div>とき: 2021年12月6日</div>
          <div>場所: ラゾーナ川崎プラザソル</div>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆出演</div>
          <ul className="ml-3">
            <li>オカダヒロエ</li>
            <li>神田初音ファレル</li>
            <li>小西善仁</li>
            <li>柴崎莉良</li>
            <li>白倉絵蓮</li>
            <li>新宅一平</li>
            <li>仙優奈</li>
            <li>高倉だりあ</li>
            <li>谷平絵梨</li>
            <li>山田暁</li>
          </ul>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>-観覧ゲスト-</div>
          <ul className="ml-3">
            <li>吉田テオポラ</li>
            <li>久保田舞</li>
            <li>佃直哉</li>
            <li>まつなみはる奈</li>
          </ul>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆スタッフ</div>
          <ul className="ml-3">
            <li>進行・解説: 数澪里</li>
            <li>舞台監督: 扇谷紗帆</li>
            <li>音響: 坂本えり</li>
            <li>照明: 尾原朱莉</li>
            <li>映像: 星野有香</li>
            <li>当日運営: 大内花音</li>
            <li>企画・運営: オドリバ</li>
          </ul>
        </div>
      </div>   
    </> : id === '4' ? <>
      <div className="text-2xl font-bold mb-4">
        ライスボールダンスクラブ
      </div>
      <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive4-1.jpg`} alt="archive4-1" width={1000} />
      <div className='flex flex-col lg:flex-row gap-3 mt-3'>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive4-2.jpg`} alt="archive4-2" width={1000} />
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive4-3.jpg`} alt="archive4-3" width={1000} />
      </div>
      <div>
        <SubTitle>公演情報</SubTitle>
        <div className="text-base lg:text-lg font-bold">ライスボールダンスクラブって？</div>
        <DetailGrayBox>
          <PText>
            ライスボールダンスクラブはオドリバが複数の振付家を招いて行うダンスショーケース、もしくはダンスクラブ。オドリバが用意した空間とスタッフワークでもって、ダンサーのクリエイティブを最大限に発揮させます。
          </PText>
        </DetailGrayBox>
        <div className="text-sm lg:text-base my-5">
          <div>とき: 2022年4月22日-24日</div>
          <div>場所: 神奈川県立青少年センタースタジオHIKARI</div>
        </div>
        <div className="my-5">
          <SubTitle>クレジット</SubTitle>
          <div className="text-sm lg:text-base my-5">
            <div>◆出演</div>
            <ul className="ml-3">
              <li>秋田乃梨子『海の気配/Functional body feels the sea』</li>
              <li>振付: 秋田乃梨子</li>
              <li>出演: 川崎萌々子、楠田東輝、秋田乃梨子</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>浅川奏絵『帰り道をなくして、この足』</li>
              <li>振付: 浅川奏絵</li>
              <li>出演: 伊藤奨、鈴木梨音、浅川奏瑛</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>伊藤奨（サンゴランプ）『白煙』</li>
              <li>振付: 伊藤奨</li>
              <li>出演: 伊藤奨、倉元奎哉、井上寛太（以上、サンゴランプ）</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>オカダヒロエ『シリアルボーイ』</li>
              <li>振付: オカダヒロエ</li>
              <li>出演: オカダヒロエ、堀川 男</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>甲斐ひろな『GOSPEL』</li>
              <li>振付・出演: 甲斐ひろな</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>神田初音ファレル（オフワンズ）『即興ラップ で始める 状況把握 合法ハーブ をノーサンキュー ボンドガール と盆ダンス 増えるぜ！フォロワー10  この作品のタイトル”Bomb Voyage”』</li>
              <li>振付: 神田初音ファレル（オフワンズ）</li>
              <li>出演: 神田初音ファレル、磯崎大介、七里海流クノー</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>平田祐香『乞う、エビデンス』</li>
              <li>振付・出演: 平田祐香</li>
            </ul>
            <br/>
            <ul className="ml-3">
              <li>山田暁『pass the pellicule』</li>
              <li>振付: 山田暁</li>
              <li>出演: 山田暁、平田祐香、岩城かのこ、岡本五</li>
            </ul>
          </div>
          <div className="text-sm lg:text-base my-5">
            <div>◆スタッフ</div>
            <ul className="ml-3">
              <li>音響: おにぎり海人</li>
              <li>照明: 松田桂一</li>
              <li>舞台監督: 吉野葵</li>
              <li>宣伝美術: 江原未来</li>
              <li>企画制作: まつなみはる奈</li>
              <li>当日運営: 金井美希</li>
              <li>撮影: 児玉健吾</li>
              <li>企画: 佃直哉</li>
              <li>　　  まつなみる奈</li>
              <li>関連企画: 石原朋香</li>
            </ul>
          </div>
        </div>
      </div>   
    </> : id === '5' ? <>
      <div className="text-2xl font-bold mb-4">
        focus01.浅川奏瑛｜人間っていいな。
      </div>
      <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive5-1.jpg`} alt="archive5-1" width={1000} />
      <div className='flex flex-col lg:flex-row gap-3'>
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive5-2.jpg`} alt="archive5-2" width={1000} />
        <ImageCenterBox src={`${process.env.REACT_APP_URL}/images/archive/archive5-3.jpg`} alt="archive5-3" width={1000} />
      </div>
      <div>
        <SubTitle>公演情報</SubTitle>
        <div className="text-sm lg:text-base">
          <div>とき: 2022年8月11日-14日</div>
          <div>場所: RAFT</div>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆振付・演出・出演</div>
          <ul className="ml-3">
            <li>浅川奏瑛</li>
          </ul>
        </div>
        <div className="text-sm lg:text-base my-5">
          <div>◆スタッフ</div>
          <ul className="ml-3">
            <li>照明: 尾原朱莉</li>
            <li>音響: おにぎり海人</li>
            <li>舞台監督: 吉野葵</li>
            <li>宣伝美術: 江原未来</li>
            <li>イラスト: 浅川奏瑛</li>
            <li>当日運営: 及川晴日</li>
            <li>撮影: 児玉健吾</li>
            <li>企画・制作: オドリバ</li>
            <li>企画: 佃直哉</li>
          </ul>
        </div>
      </div>   
    </> : 
      <Navigate to="/notfound" />
    }
  </>)
}