import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

function Twitter() {
  return (
    <div>
      <TwitterTimelineEmbed
      sourceType="profile"
      screenName="odoriba_rice"
      options={{ height: 600, width: "100%" }}
      />
    </div>
  );
}

export default Twitter;
