import { Link } from "react-router-dom";
import { ImageCenterBox } from "../parts/BoxBase";
import Contents from "./parts/Contents";
import NewsList from "./parts/NewsList";
import Twitter from "./parts/Twitter";


export default function Index() {
  return (
    <>
      <Link to="/next">
      <ImageCenterBox
        src={`${process.env.REACT_APP_URL}/images/fryer.jpg`}
        alt="フライヤー"
        width={500}
      />
      </Link>
      {/*
      <Contents />
      */}
      <div className="mt-10 lg:mt-20">
        <h2 className="text-xl lg:text-2xl font-bold text-gray-600 mb-3">News</h2>
        <NewsList />
      </div>
      <div className="mt-10 lg:mt-20">
        <Twitter />
      </div>
    </>
  )
}